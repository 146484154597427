const clientData = {
    client_entity: 4,
    attorney_firm: 'Law Office of Vincent R. Vidmer',
    attorney_name: 'Vincent Vidmer',
    attorney_number: '773-704-5640',
    attorney_email: 'vincentvidmer@vrvlaw.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_accounting_and_business_partners+(720p).mp4',
    mainColor: '#2f2f2f',
    secondaryColor: '#840017',
}

export default clientData